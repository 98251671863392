// 节流函数
export function throttle(fn,wait){
  let timer;
  return function(...args){
    if(timer) return
    timer = setTimeout(()=>{
      fn.apply(this,args);
      timer = null;
    },wait)
  }
}