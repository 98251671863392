import React from "react";
import NavBar from "../components/NavBar/index";
import { renderRoutes } from "react-router-config";
import styles from "./index.module.css";

function HomeLayout(props){
	const { route ,location } = props;
	const hiddenNav = (route.routes.find(route=>route.path === location.pathname) || {}).hiddenNav;
	return (
		<div className={ styles.layout }>
			{ !hiddenNav && <NavBar />}
			<div className={ styles.content }>
				{renderRoutes(route.routes)}
			</div>
		</div>
	)
}

export default HomeLayout;