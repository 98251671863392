import { useEffect, useMemo, useState } from "react";
import articleMenus from "../../articles/index";
import styles from "./index.module.css"

function Summary(props){
  const [ navs, setNavs ] = useState([])
  useEffect(()=>{
    const articleIndex = articleMenus.findIndex(item => item.id === props.id);
    const navArr = articleMenus.slice(articleIndex<3?0:(articleIndex-3), articleIndex+3).map(item => ({
      id: item.id,
      title: item.title,
      active: item.id === props.id,
      path: `./article/1`
    }))
    setNavs(navArr)
  }, [props.id])

  return useMemo(()=>(
    <div className={ styles.box }>
      <h2 className={ styles.title } key={'1223'}>导航 SUMMARY</h2>
      {
        navs.map((item,index) => (
          <div key={item.id}>
            <a className={`${styles.label} ${ item.active ? styles.active :''}`} onClick={()=>{props.linkTo(item.id)}}>{index+1}. { item.title }</a>
            { item.active && 
              <div className={ styles.process }>
                <div className={ styles.processActive } style={{
                  width:`${props.process}%`
                }}></div>
              </div> 
            }
          </div>
        ))
      }
    </div>
  ),[props.process,navs])
}
export default Summary;