import React,{useState} from "react";
import { Link } from "react-router-dom"
import avatar from "../../assets/avatar.jpg"
import styles from "./index.module.css";

function NavBar(){
	const [ activeName, setActiveName ] = useState('home');
	const navs = [
		{
			title:"文章",
			path:"/home",
			name:"home"
		},
		{
			title:"分类",
			path:"/category",
			name:"category"
		},
		{
			title:"随笔",
			path:"/essay",
			name:"essay"
		},
		{
			title:"友情链接",
			path:"/links",
			name:"links"
		}
	]
	return (
		<div className={styles.navBar}>
			<div className={styles.info}>
				<img className={styles.avatar} src={avatar} alt="lucas头像" />
				<span className={styles.name}>Lucas （'_'）</span>
			</div>
			<div className={styles.menus}>
				{
					navs.map(nav=>{
						return (
							<Link 
								key={nav.title} 
								className={ `${styles.nav} ${activeName === nav.name ? styles.active : '' }` } 
								to={nav.path} 
								onClick={() => setActiveName(nav.name)}
							>
								{nav.title}
							</Link>
						)
					})
				}
			</div>
		</div>
	);
}
export default React.memo(NavBar);

