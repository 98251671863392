import React from "react";
import buildingSrc from "../../assets/building.png";
import styles from "./index.module.css";

function Empty(){
	return (
		<div className={styles.empty}>
			<img src={buildingSrc} alt="建设" />
			<p className={styles.text}>正在建设中，敬请期待...</p>
		</div>
	)
}
export default React.memo(Empty);