import React, { useState , useEffect, useMemo } from "react";
import gfm from 'remark-gfm' // 支持表格
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'
import styles from "./index.module.css"
// 组件
import ReactMarkdown from 'react-markdown'
import Summary from "@/components/Summary";
import articleMenus from "@/articles/index.js";
import { useParams } from "react-router-dom";
import {throttle} from "@/utils/index.js";

function ArticleDetail(props){
	const [ markdownTxt, setMarkdownTxt ] = useState('');
  const [ articleInfo, setArticleInfo] = useState({})
  const [ process, setProcess] = useState(0)
  const { id } =  useParams();
	const renderers = {
		code: ({language, value}) => {
			return <SyntaxHighlighter style={vscDarkPlus} language={language} children={value} />
		}
	}

	const getTxt = async (id) => {
    const articleInfo = articleMenus.find(item=>item.id === id);
    const artileName = articleInfo?.title || '';
		fetch(require(`@/articles/${artileName}.md`).default).then(res=>res.text()).then(text=>{
			setMarkdownTxt(text)
      setArticleInfo(articleInfo)
      setProcess(1)
		})
	}

  const srcollHanle = () => {
    return throttle((event)=>{
      const { scrollTop,scrollHeight,clientHeight } = event.target;
      // 滚动进度：已经滚动的高度/需要滚动的总高度
      // 需要滚动的总高度 = 文档高度 - 可视区域高度
      const process = Math.floor( scrollTop/(scrollHeight - clientHeight)*100 )
      setProcess(process)
    },300)
  }

  const srcollFn = srcollHanle()

  const linkTo = (id) => {
    props.history.push(`/article/${id}`)
  }

	useEffect(()=>{
		getTxt(id);
	},[id])

	return (
		<div className={styles.detail} >
			<div className={styles.summary}>
        <Summary process={process} id={id} linkTo={linkTo}></Summary>
      </div>
      <div className={`${styles.content} scroll-box`} onScroll={srcollFn} key={id}>
        <h1>{articleInfo.title}</h1>
        { articleInfo.mainImg && <img className={styles.mainImg} src={articleInfo.mainImg} alt={articleInfo.title} />}
        <div className={styles.mdBox}>
          {
            useMemo(()=>{
              return (
                <ReactMarkdown renderers={renderers} plugins={[gfm]}  children={markdownTxt}>
                </ReactMarkdown>
              )
            },[markdownTxt])
          }
        </div>
      </div>
		</div>
	)
}
export default React.memo(ArticleDetail);