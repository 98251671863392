import styles from "./index.module.css";

function ArticleCard(props){
  const { articleInfo, linkToDetail} = props;
  return (
    <div className={styles.articleBox} key={articleInfo.id}>
      <p className={styles.title} onClick={()=>linkToDetail(articleInfo.id)}>{articleInfo.title}</p>
      <p>
        <span className={styles.createTime}>{articleInfo.createTime}</span>
        <i className={styles.icon}></i>
        {
          articleInfo.tags.map(tag=>{
            return (
              <span className={styles.tag} key={tag}>{tag}</span>
            )
          })
        }
      </p>
    </div>
  )
}
export default ArticleCard;