import React, { Suspense } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout.js";
import Home from "../pages/home/index";
import ArticleDetail from "../pages/article/index";
import Empty from "../components/Empty/index.js";
import Clock from "../pages/clock/index";

const SuspenseComponent = Component => props => {
  return (
    <Suspense fallback={null}>
      <Component {...props}></Component>
    </Suspense>
  )
}

const routes = [
	{
		component: HomeLayout,
		routes:[
			{
				path: "/",
				exact: true,
				render: () => <Redirect to={"/home"} />
			},
			{
				path: "/home",
				component: SuspenseComponent(Home),
			},
			{
				path: "/article/:id",
				component: SuspenseComponent(ArticleDetail),
			},
			{
				path: "/notFound",
				component: SuspenseComponent(Empty),
			},
			{
				path: "/clock",
				hiddenNav:true,
				component: SuspenseComponent(Clock),
			},
			{
				path: "*",
				exact: false,
				render: () => <Redirect to={"/notFound"} />
			}
		]
	}
]

export default routes;