import React,{useState} from "react";
// import axios from "axios";

function Clock(){

	const [ hasClocked ] = useState(false);
	// const [ id, setId ] =  useState('');
	// const [ leftList, setLeftList ] =  useState([]);
	// const [ rightList, setRightList ] =  useState([]);

	// // 检测是否打卡
	// const checkClock = async () => {
	// 	const result = await axios.get(`/search?keywords=`);
	// 	setHasClocked(true);
	// 	setId(1);
	// 	return result;
	// }

	// // 打卡
	// const doClock = async () => {
	// 	try {
	// 		const result = await axios.post(`/search?keywords=`);
	// 		("打卡成功")
	// 	} catch (error) {
	// 		throw new Error(error);
	// 	}
	// }

	// // 更新打卡
	// const updataClock = async () => {
	// 	try {
	// 		const result = await axios.post(`/search?keywords=`);
	// 		("打卡成功")
	// 	} catch (error) {
	// 		throw new Error(error);
	// 	}
	// }

	// // 打卡记录列表
	// const getRecordsList = () => {
	// 	console.log();
	// }

	// const RecordItem = (item) => {
	// 	const { data , key } = item;
	// 	return (
	// 		<div className="info-box" key={key}>
	// 			<p>开始：{data.startTime}</p>
	// 			<p>结束：{data.endTime}</p>
	// 			<p>学习时长：{data.value}分钟</p>
	// 		</div>
	// 	)
	// }


	// setLeftList([{
	// 	startTime:'2021-03-06 15:23:53',
	// 	endTime:'2021-03-06 19:35:26',
	// 	value: 214,
	// }])

	

	return (
		<div className="clock">
			<div>
				<p className="name">老付</p>
				<div className="button">{hasClocked?"结束打卡":"开始打卡"}</div>
				<div className="record-box">
					<p className="title">打开记录</p>
					{/* {
						_.map(leftList,item=>{
							return <RecordItem data={item} key={item.value}></RecordItem>
						})
					} */}
					
				</div>
			</div>
			<div>
				<p className="name">老朱</p>
				<div className="button">打卡</div>
				<div className="record-box">
					<p className="title">打开记录</p>
					{
						// _.map(rightList,item=>{
						// 	return RecordItem(item)
						// })
					}
				</div>
			</div>
		</div>
	)
}

export default React.memo(Clock);

