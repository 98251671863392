import React from "react";
import articleMenus from "../../articles/index";
import styles from "./index.module.css";
import ArticleCard from "../../components/ArticleCard";

function Home(props){

	const linkToDetail = (id) => {
		props.history.push(`/article/${id}`)
	}

	return (
		<div className={styles.box}>
			<div className={`${styles.list} no-scrollbar`}>
				{articleMenus.map(article=>{
					return (
            <ArticleCard articleInfo={article} linkToDetail={linkToDetail} key={article.id}></ArticleCard>
					)
				})}
			</div>
      <a className={styles.beian} href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备2022001891号-1</a>
		</div>
	)
}
export default React.memo(Home);